@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');


:root{
  --transition: all 300ms ease-in-out;
  --dark-color: #332e29;
  --light-color: #fff;
}


* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

/* .hell{
    width: 100%;
    height: 15vh;
    background-color: rgba(0,0,0,0.5);
    margin: auto;
  }

  .in{
    width: 70%;
    margin: auto;
    align-items: center;
    text-align: center;
    padding-top: 10px;
    color: white;
  } */

/* =========header====== */
#head {
  position: relative;
}

#header1 {
  background-image: url("../../public/images/ban2.jpg");
  background-size: cover;
  position: fixed;
  background-repeat: no-repeat;
  background-position: center;
  filter: grayscale(1);
  width: 100%;
  height: 700px;
}


#header1::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 700px;
  background-color: rgba(0, 0, 0, 0.5);
}

#header {
  justify-content: flex-start;
  align-items: flex-end;
  color: white;
  font-family: 'Poppins', sans-serif;
  width: 100%;
  height: 700px;
}


.bnd-d {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0% auto 3%;


}

.brand {
  width: 300px;
  height: 300px;
  animation: brand 1500ms;
  transition: transform .7s ease-in-out;
  z-index: 3;
  margin-top: 29%;
  position: absolute;
}

@keyframes brand {
  0% {
    transform: rotate(0deg);
  }

  /* 50%{
        transform: rotate(180deg);
    } */
  100% {
    transform: rotate(360deg);
  }
}

.brand:hover {
  transform: rotate(360deg);
}

.contain {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  margin-bottom: 5%;
}

#header .contain {
  width: 100%;
}

.header-content {
  margin-top: 20%;
  position: absolute;
  z-index: 2;
}

.header-content h1 {
  font-size: 4.5rem;
}

.header-content h5 {
  font-size: 200;
  letter-spacing: 4px;
  margin-top: -1.5rem;
}

.header-content .ico {
  width: 30px;
  height: 30px;
  color: #fff;
}

.py-7 {
  padding: 7rem 0;
}

/* button down and title -reusable */
.btn-down{
  font-size: 3rem;
  display: inline-block;
  margin-top: 1rem;
  transition: var(--transition);
}
.btn-down:hover{
  opacity: 0.8;
  transform: translateY(5px);
}
.btn-down-white{
  color: white;
}
.title{
  margin-bottom: 5rem;
}
.title h2{
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: 600;
  position: relative;
}
.title h2::after{
  position: absolute;
  content: "";
  top: 120%;
  left: 0;
  width: 70px;
  height: 4px;
  background-color: black;
}


/* =======tag===== */



.tgsec{
  position: absolute;
  background-color:white;
  width: 100%;
  z-index: 2;
}

.container1 {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.tg-cnt {
  width: 100%;
  height: 60vh;
  background-color: rgb(247, 246, 242);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tag {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 30px;
  line-height: 30px;
}

.snc {
  margin-inline: 7px;
  font-weight: 700;
}

.line {
  width: 70px;
  height: 4px;
  margin: 20px;
  background-color: #000;
}

/*======= about======= */


.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
}

.about-img {
  width: 100%;
  height: auto;
}

.grid{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.about-left {
  width: 50%;
  margin-bottom: 4rem;
  height: 480px;
  overflow: hidden;
}

.about-right {
  width: 50%;
}

.lead {
  opacity: 0.8;
  padding: 1rem 0;
  font-weight: 400;
  line-height: 1.8;
}





/* =====work====== */

.sec2{
  color: white;
  margin-block: 5%;
}

.workimg {
  background-image: url("../../public/images/work-pic.jpg");
  background-attachment: fixed;
  background-size: cover;
  position:-webkit-sticky;
  z-index: 1;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
}



.work{
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.4)
}

.work-content{
  width: 80%;
  margin-block: 5%;
  z-index: 4;
}

#work .title h2, #work h3{
  color: white;
}
#work .title h2::after{
  background: white;
}
.work-bottom{
  margin-top: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: center;
}
.work-bottom > div .icon img{
  width: 50px;
  margin: 0 auto 1.7rem auto;
}

.workbt{
  width: 50px;
  height: 50px;
}

/* ======colors====== */

.col-d{
  width: 100%;
  margin-block: 10%;
  display: flex;
  justify-content: center;
}
.color{
  width: 80%;
  background: black;
  text-transform: uppercase;

}

.t1 .t2 .t3{
  width: 100%;
  /* background-clip: 'text'; */
  
  
}

.t1{
  font-size: 30px;
  padding-left: 10%;
  padding-top: 8%;
  margin-bottom: -5%;
  background: conic-gradient(from 90deg, white 50%, yellow,blue, green ,red,white
  ) fixed;
  background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
}

.t2{
  display: flex;
  justify-content: center;
  font-size: 200px;
  background: conic-gradient(from 90deg, white 50%, yellow,blue, green ,red,white
  ) fixed;
  /* background-size: 78%; */
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}
.t3{
  display: flex;
  justify-content: flex-end;
  font-size: 50px;
  padding-right: 10%;
  margin-top: -3%;
  margin-bottom: 3%;
  background: conic-gradient(from 90deg, white 50%, yellow,blue, green ,red,white
  ) fixed;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

/* =====portfolio===== */

.portfolio{
  width: 100%;
}
.portfolio-grid > div{
  transition: var(--transition);
}
.portfolio-grid > div:hover{
  transform: scale(0.9);
}

.portfolio-grid{
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3,1fr);
}

.grid-col-span{
  grid-column: span 2;
}

/* images */
.img1{
  position: relative;

}

.im2{
  width: 50%;
}

.im1{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.im1-txt{
  position: absolute;
  background:rgba(0,0,0,0.5);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.im2-txt{
  position: absolute;
  background:rgba(0,0,0,0.5);
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.im2-d{
  width: 50%;
}

.exp{
  font-size: 20px;
  color: #fff;
}

a{
  text-decoration: none;
}

.im1-td{
  color: #fff;
  font-size: 60px;
  font-weight: 500;
}

.im4-td{
  color: #fff;
  font-size: 45px;
  font-weight: 500;
  text-align: center;
}
.exp:hover{
  cursor: pointer;
  scale: 1.2;
  transition: var(--transition);
}

/* ======contact===== */
.sec4{
  width: 100%;
  
}

#contact{
  background-color: rgb(248, 248, 248);
}
.contact-content{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin-block: 5%;
}
.contact-left{
  margin-bottom: 5rem;
}

.contact-right{
  border: 5px solid rgb(50, 49, 49);
  width: 100%;
}
.contact-left .lead{
  padding: 0.5rem;
}

.map{
  width: 100%;
  height: 100%;
}

/* =======insta===== */

.sec5{
  width: 100%;
  height: 80vh;
  /* overflow: hidden; */
}

#insta{
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2;
  position: absolute;
  /* min-height: 65vh; */
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.insta-content{
  width: 100%;
}



.insta-grid{
  display: grid;
  grid-template-columns: repeat(4,1fr);
}

.inst-img{
  background-image: url("../../public/images/insta-pic.jpg");
  background-size: cover;
  /* position: fixed; */
  background-attachment: fixed;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  z-index: 1;
}

#insta .title h2{
  color: var(--light-color);
}
#insta .title h2::after{
  background-color: var(--light-color);
}
.insta-grid > div{
  height: 400px;
  overflow: hidden;
  transition: var(--transition);
}
.insta-grid > div img{
  height: 60%;
  object-fit: cover;
}
.insta-grid > div:hover{
  transform: scale(0.9);
}

.in-img{
  width: 100%;
  border: 5px solid white;
  /* height: 100px; */
}


/* ====footer===== */

.sec6{
  width: 100%;
  margin-bottom: 0%;
  /* height: 90vh; */
 /* background-color: red; */
}


/* ======sec7===== */
.sec7{
  
 background-color: black;
  width: 100%;
  height: auto;
  margin: 5% auto;
  padding: 3% 3%;
}

.sec7>.title{
  color: #fff;
}

.sec7>.title>h2::after{
  background: #fff;
}

.gallary{
  display: flex;
  justify-content: center;
  align-items: center;
}

.sc-gsFSXq{
  background-color: #fff;
}

.vid-frame{
  width: 528px;
  height: 297px;
}

.did-vid{
  align-items: center;
  justify-content: center;
}

.iNpnDY{
  background-color: #fff;
}



#footer{
  background-color: var(--dark-color);
  color: var(--light-color);
}
.footer-content{
  margin-block: 5%;
  text-align: center;
}
.footer-content h3{
  color: var(--light-color);
  font-size: 1.5rem;
  letter-spacing: 1px;
}
.footer-content ul{
  list-style: none;
  display: flex;
  flex-direction: row;
}
.footer-content ul li a{
  color: var(--light-color);
  font-size: 1rem;
  font: bold;
  transition: var(--transition);
  list-style: none;
  text-decoration: none;
}
.footer-content ul li a:hover{
  opacity: 0.7;
}
.footer-content ul li{
  margin: .5rem 1rem;
}
.footer-content > div:first-child{
  margin-bottom: 3rem;
}
.footer-content > div:first-child div{
  width: 100px;
  height: 100px;
  overflow: hidden;
  margin: 5rem auto;
  border-radius: 50%;
}

.foot-logo{
  width: 100%;
  object-fit: contain;
}



/* =======media queries====== */

@media(max-width:1200px){
  .header-content{
    margin-top: 30%;
  }
  .header-content h1 {
    font-size: 3rem;
  }
  
  .header-content h5 {
    letter-spacing: 6px;
    margin-top: 1rem;
  }

  .sec5{
    height: 800px;
  }

  #insta{
    height: 800px;
  }
  .inst-img{
    height: 800px;
  }

  .sec7{
    height: auto;
  }
  /* .sec7{
    height: 90vh;
  } */

  .vid-frame{
    width: 400px;
    height: 225px;
  }

  .t1{
    font-size: 20px;
  }

  .t2{
    font-size: 150px;
  }

  .t3{
    font-size: 30px;
  }


}

@media (max-width:950px){

  #header1{
    margin-top: -20%;
    height: 110vh;
  }

  #header1::after{
    height: 200vh;
  }

  .bnd-d{
    margin-top: 15%;
  }

  .brand{
    width: 500px;
    height: 500px;
  }

  .header-content{
    margin-top: 40%;
    margin-bottom:15% ;
    
  }

  .header-content h1{
    font-size:2rem;
  }

  .header-content h5{
    margin-block: 12%;
    font-size:1.3rem;
    font-weight: 100;
    letter-spacing: 6px;

  }

  .about{
    width: 100%;
  }

  .tg-cnt{
    height: 40vh;
    margin-top: 5%;
  }

  .tag{
    font-size: 25px;
    font-weight: 500;
  }

  .snc{
    font-weight: 700;
  }

  .about1{
    width: 100%;
    margin-top: -10%;
  }

  .about-content{
    flex-direction: row;
    gap: 2rem;
  }

  .about-left{
    width: 100%;
    display: flex;
    align-items: center;
    margin-block: auto;
  }

  .about-right{
    width: 100%;
    line-height: 1%;
  }

  .work{
    height: 850px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .work-content{
    width: 100%;
  }

  .work-top li{
    width: 100%;
    padding: 0;
    font-size: 22px;
    list-style: none;
  }

 .grid{
    padding: 0% 0% 0% 0%;
  }


  .workimg{
    height: 850px;
    background-position: center;
  }

  .portfolio-grid{
    grid-template-columns: 1fr;
  }

  .grid-col-span{
    grid-column: span 1;
  }

  .im1-td,.im4-td{
    font-size: 50px;
  }
  
  a{
    list-style: none;
    text-decoration: none;
  }

  .vid-frame{
    width: 320px;
    height: 180px;
  }


  .contact-content .lead{
    font-size: 1.5rem;
  }

  .contact-right{
    height: 350px;
  }

  .tgsec{
    width: 100vw;
  }

  .insta-grid{
    grid-template-columns: repeat(2,1fr);
    gap: 1rem;
  }

  .insta-grid > div{
    height: 300px;
  }
  .insta-grid > div img{
    height: 100%;
  }

  .sec5{
    height: 1300px;
  }

  .sec7{
    height: auto;
  }
  /* .sec7{
    height: 50vh;
  } */

  #insta{
    height: 1300px;
  }
  .inst-img{
    height: 1300px;
  }

  .footer-content ul{
    flex-direction: row;
    background-position: right;
    padding: 0%;
  }

  .col-d{
    margin-block: 30%;
  }

  .t2{
    font-size: 120px;
  }
}
  

@media (max-width:700px) {
  #header1{
    margin-top: -25%;
  }
  .bnd-d{
    margin-top: 25%;
  }
  .brand{
    width: 350px;
    height: 350px;
  }

  .header-content{
    margin-top: 60%;
    
  }

  .tag{
    flex-direction: column;
  }

  .about-content{
    flex-direction: column;
  }

  .work-top li{
    font-size: 16px;
  }

  .work-bottom h3{
    font-size: 17px;
  }

  .contact-content{
    flex-direction: column;
    margin-block: 15%;
  }

  .contact-content .lead{
    font-size: 1.2rem;
  }

  #insta{
    height: 900px;
  }

  .inst-img{
    height: 900px;
  }

  .sec5{
    height: 900px;
  }

  .sec7{
    height: auto;
  }
  /* .sec7{
    height: 70vh;
  } */
  .t1{
    font-size: 15px;
  }

  .t2{
    font-size: 60px;
  }

  .t3{
    font-size: 18px;
  }

}

@media (max-width:500px){

  #header1{
    margin-top: -65%;
    height: 100vh;
  }

  .bnd-d{
    margin-top: 75%;
  }

  .brand{
    width: 200px;
    height: 200px;
    margin-top: 70%;
  }

  .header-content{
    margin-top: 80%;
    
  }

  .header-content h1{
    font-size:1.7rem;
  }

  .header-content h5{
    font-size:1rem;
  }

  .tg-cnt{
    margin-top: -20%;
  }

  .tag{
    font-size: 15px;
  }

  .about-left{
    margin: -25% auto;
  }

  .about-right{
    margin-top: 10%;
  }

  .work-top{
    display: flex;
    flex-direction: column;
  }

  .work-top li{
    font-size: 17px;
  }

  .work-bottom{
    flex-direction: column;
  }

  .im1-td,.im4-td{
    font-size: 30px;
  }

  .vid-frame{
    width: 100%;
    height: 162px;
  }

  .contact-content .lead{
    font-size: 1rem;
  }

  .contact-right{
    height: 250px;
  }

  .insta-grid{
    grid-template-columns: 1fr;
  }

  .insta-grid > div{
    height: 250px;
  }

  .sec5{
    height: 1300px;
  }

  .sec7{
    height: auto;
  }
  /* .sec7{
    height: 40vh;
  } */


  #insta{
    height: 1300px;
  }
  .inst-img{
    height: 1300px;
  }

  .footer-content ul{
    flex-direction: column;
  }

  .t1{
    font-size: 15px;
  }

  .t2{
    font-size: 60px;
  }

  .t3{
    font-size: 18px;
  }

}

@media (max-width:400px) {

  #header1,#header{
    height: 800px;
  }
  #header1::after{
    height: 800px;
  }

  #header{
    margin-top: -18%;
  }

  .sec7{
    height: auto;
  }
  

  .t2{
    font-size: 60px;
  }

  
}

@media (max-width:300px){

  #header1{
    margin-top: -70%;
  }

  .header-content{
    margin-bottom:15% ;
    
  }

  .header-content h5{
    font-size:1rem;
  }
  

  .about-left{
    margin: -70% auto;
  }

  .about-right{
    margin-top: 2%;
    line-height:1%;
  }

  .im1-td,.im4-td{
    font-size: 30px;
  }

  .footer-content ul{
    flex-direction: column;
  }


  .t1{
    font-size: 12px;
  }

  .t2{
    font-size: 40px;
  }

  .t3{
    font-size: 16px;
  }
  
}











