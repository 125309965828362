* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  .pro-con{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-block: 5%;
  }

  .pro-d{
    display: flex;
    justify-content: center;
    align-items: center;
  }

 

