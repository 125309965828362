/* ===================================
========= register page  */
.form-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
  flex-direction: column;
  background-color: #ffdee9;
  background-image: linear-gradient(0deg, #ffdee9 0%, #b5fffc 100%);
}

.form-container form {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 20px;
  background-color: #fff;
}

.form-container form .title {
  text-align: center;
  margin-bottom: 15px;
  font-weight: bold;
  font-family: "Playfair Display", serif;
}

.form-container form input {
  border: none;
  border-bottom: 1px solid #000;
  border-radius: 0;
}

.form-container form button {
  border: 1px solid black;
  border-radius: 0;
  background-color: #000;
  color: white;
  width: 250px;
}

.form-container form button:hover {
  border: 1px solid black;
  background: #000000; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #434343,
    #000000
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #434343,
    #000000
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.card{
  width: 18rem;
}

.d-flex{
  margin-bottom: 5%;
}


.card-img-top{
  width: 200px;
  height: 200px;
  margin: 2% auto;
}

.car-img{
  height: 400px;
  width: 400px;
}

.photos{
  justify-content: space-around;
}

@media (max-width:900px) {

  .product-link {
    width: 150px;
    height: 150px;
  }

  .card{
    width: 150px;
    height: 150px;
  }

  .card-body{
    width: 150px;
    height: 150px;
  }

  .card-img-top{
    width: 100px;
    height: 100px;
  }



  .card-title{
    font-size: small;
  }
  
}

@media (max-width:600px) {
  .car-img{
    height: 250px;
    width: 250px;
  }
}
